import { Button, Flex, Input, Layout, Row, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import style from './KhoiPhucMatKhau.module.less'
import { headerLogo, loginBanner, logoNSMP } from '../../../assets/img';
import { EyeInvisibleOutlined, EyeOutlined, EyeTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { apis, keys, paths } from '../../../constants';
import AxiosService from '../../../common/AxiosService';
import cn from 'classnames';

const KhoiPhucMatKhau = (props) => {
  const { token } = useParams();
  const [username, set_username] = useState('')

  const [password, set_password] = useState('');
  const [confirmPassword, set_confirmPassword] = useState('');

  const [passwordError, set_passwordError] = useState('');
  const [confirmError, set_confirmError] = useState('');
  const [sentSuccess, set_sentSuccess] = useState(false)
  const [isTokenValidate, set_isTokenValidate] = useState(true);

  const dispatch = useDispatch()
  const auth = useSelector(state => state.auth)
  const history = useHistory()

  useEffect(() => {
    let current = new Date().getTime()
    if (!!auth.token && current < auth.loginTime + 86400000) {
      history.push(paths.main_tong_quan)
    }
  }, [auth, history])

  useEffect(() => {
    checkValidateToken(token)
  }, [token])

  const checkValidateToken = () => {
    let requestBody = {
      code: token
    }

    AxiosService.post(apis.Url_Api_KiemTraTokenDoiMK, requestBody)
      .then(response => {
        let { data } = response;
        if (!!data && !! data.data && data.data.message === keys.api_message_VALID) {
          set_isTokenValidate(true)
          set_username(data.data.username)
        } else {
          set_isTokenValidate(false)
        }
      })
      .catch(err => console.log(err))
  }

  const onPressQuenMatKhau = () => {
    if (isFormValidate()) {
      let requestBody = {
        code: token,
        username: username,
        password: password,
        isFirstActive: "0"
      }
      console.log(requestBody);
      AxiosService.post(apis.Url_Api_DoiMK, requestBody)
        .then(response => {
          let { data } = response;
          if (!!data && data.message === "OK") {
            set_sentSuccess(true)
          } else  {
            notification.error({message: data.message})
          }
        })
        .catch(err =>  notification.error({message: err.message}))
    }
  }

  const isFormValidate = () => {
    let valid = true;
    if (!password) {
      set_passwordError("Vui lòng nhập mật khẩu mới")
      valid = false;
    }

    if (!password) {
      set_confirmError("Vui lòng xác nhận mật khẩu mới")
      valid = false;
    }

    if (password !== confirmPassword) {
      set_confirmError("Mật khẩu không trùng khớp")
      valid = false;
    }

    return valid;
  }

  const onChangePassword = (val) => {
    set_password(val)
    set_passwordError('')
  }

  const onChangeConfirmPassword = (val) => {
    set_confirmPassword(val)
    set_confirmError('')
  }

  const onPressToLogin = () => {
    history.push(paths.dang_nhap)
  }

  return (<div className={style["flex-container"]}>
    <Layout className={style["base-layout"]}>
      <Layout.Header className={style["layout-header"]}>
        <Flex align='center' style={{ height: '100%' }}>
          <img src={headerLogo} className={style["logo-header"]} />
        </Flex>
      </Layout.Header>
      <Layout.Content>
        {!!isTokenValidate ? 
        <div className={style["layout-content"]}>{!sentSuccess ? <div className={style["form-box"]}>
          <div className={style["form-title"]}>
            KHÔI PHỤC TÀI KHOẢN
          </div>
          <div className={style["form-layout"]}>
            Mật khẩu mới
            <Input.Password className={cn(style["form-input"], !!passwordError && style["error"])} value={password} onChange={({ target }) => onChangePassword(target.value)} security='password'
              iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
            <div className={style["error-input"]}>{passwordError}</div>
          </div>
          <div className={style["form-layout"]} style={{ marginTop: '40px' }}>
            Nhập lại mật khẩu
            <Input.Password className={cn(style["form-input"], !!confirmError && style["error"])} value={confirmPassword} onChange={({ target }) => onChangeConfirmPassword(target.value)} security='password'
              iconRender={(visible) => (visible ? <EyeOutlined /> : <EyeInvisibleOutlined />)} />
            <div className={style["error-input"]}>{confirmError}</div>
          </div>
          <div className={style["error-request"]}>{auth.message}</div>
          <Button className={style["form-button"]} onClick={onPressQuenMatKhau}>Xác nhận</Button>
        </div> :
          <div className={style["form-box"]}>
            <div className={style["form-title"]}>
              QUÊN MẬT KHẨU

            </div>
            <div className={style["form-layout"]}>
              <div className={style["message-success"]}>
                Mật khẩu đã được thay đổi thành công!

              </div>
            </div>
            <div className={style["error-request"]}>{auth.message}</div>
            <Button className={style["form-button"]} onClick={onPressToLogin}>Đăng nhập ngay</Button>

          </div>}
        </div> : <div className={style["layout-content"]}> 
          <div className={style["invalid-token"]}>
            Đường dẫn hết hạn hoặc không khả dụng, vui lòng kiểm tra lại!
          </div> 
          <div className={style["login-link"]} onClick={onPressToLogin}>
            Về trang đăng nhập
          </div> 
        </div> }
      </Layout.Content>
    </Layout>
  </div>
  )
};

export default KhoiPhucMatKhau;
