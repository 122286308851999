export const THICC_Master = [
    {
        value: 1,
        label: '1 mm',
        min: 0.95,
        max: 1.04,
    },
    {
        value: 1.1,
        label: '1.1 mm',
        min: 1.045,
        max: 1.14,
    },
    {
        value: 1.2,
        label: '1.2 mm',
        min: 1.14,
        max: 1.24,
    },
    {
        value: 1.4,
        label: '1.4 mm',
        min: 1.33,
        max: 1.44,
    },
    {
        value: 1.5,
        label: '1.5 mm',
        min: 1.425,
        max: 1.54,
    },
    {
        value: 1.6,
        label: '1.6 mm',
        min: 1.52,
        max: 1.64,
    },
    {
        value: 1.8,
        label: '1.8 mm',
        min: 1.71,
        max: 1.84,
    },
    {
        value: 2,
        label: '2 mm',
        min: 1.9,
        max: 2.04,
    },
    {
        value: 2.3,
        label: '2.3 mm',
        min: 2.185,
        max: 2.34,
    },
    {
        value: 2.4,
        label: '2.4 mm',
        min: 2.28,
        max: 2.44,
    },
    {
        value: 2.5,
        label: '2.5 mm',
        min: 2.375,
        max: 2.54,
    },
    {
        value: 2.8,
        label: '2.8 mm',
        min: 2.66,
        max: 2.84,
    },
    {
        value: 3,
        label: '3 mm',
        min: 2.85,
        max: 3.04,
    },
    {
        value: 3.2,
        label: '3.2 mm',
        min: 3.04,
        max: 3.24,
    },
    {
        value: 3.4,
        label: '3.4 mm',
        min: 3.23,
        max: 3.44,
    },
    {
        value: 3.5,
        label: '3.5 mm',
        min: 3.325,
        max: 3.54,
    },
    {
        value: 4,
        label: '4 mm',
        min: 3.8,
        max: 4.04,
    },
];
