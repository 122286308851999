export const MISA_NAME_ACRONYM = [
  {
    label: "Sản xuất ống",
    value: "B",
  },
  {
    label: "Xẻ hàng",
    value: "SLT",
  },
  {
    label: "Xuất bên ngoài",
    value: "SR",
  },
];

export const ROLL_COIL_STATUS = [
  {
    label: "Tất cả",
    value: null,
  },
  {
    label: "Tồn kho",
    value: "0",
  },
  {
    label: "Đã xuất kho",
    value: "1",
  },
  {
    label: "Đợi phê duyệt",
    value: "2"
  },
  {
    label: "Đã phê duyệt",
    value: "3"
  }
]

export const CONSIGNMENT = [
  {
    label: "Kí gửi",
    value: "1"
  },
  {
    label: "NSMV",
    value: "0"
  }
]
