import moment from "moment";
export const isValidEmail = mail => {
    var regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !!mail && regex.test(mail);
};

/**
 * Validate xem object có field nào trống không
 * @param {*} form Object cần validate
 * @param {*} notRequired Bỏ validate các trường này
 * @returns
 */
export const isValidForm = (form, notRequired = []) => {
    let isValid = true;
    for (const key of Object.keys(form)) {
        if (!notRequired.includes(key) && typeof form[key] == 'string' && form[key].trim() === '') {
            isValid = false;
        }
    }
    return isValid;
};

export const compareCurrentDate = (date1) => {
    if (!date1) return -1;
    // Split the date strings into day, month, and year
    const [day1, month1, year1] = date1.split('/').map(Number);
    const [day2, month2, year2] = moment().format('DD/MM/YYYY').split('/').map(Number);

    // Create date objects from the split values
    const d1 = new Date(year1, month1 - 1, day1);
    const d2 = new Date(year2, month2 - 1, day2);

    if (d1.getTime() > d2.getTime()) {
        return 1; // date1 is newer
    } else if (d1.getTime() < d2.getTime()) {
        return -1; // date2 is newer
    } else {
        return 0; // dates are the same
    }
};
