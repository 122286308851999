import { Spin } from "antd";
import { useEffect } from "react";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { paths } from "../constants";
import { KhoiPhucMatKhau, Login, QuenMatKhau } from "../pages/auth";
import { NotFound } from "../pages/common";

const AuthRoute = ({ location }) => {
  const { switcher, status, themes } = useThemeSwitcher();
  const { pathname } = location;

  useEffect(() => {
    const isAuth = pathname.slice(0, 5) === paths.auth;
    switcher({ theme: isAuth ? themes.auth : themes.main });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // Avoid theme change flicker
  if (status === "loading") {
    return <Spin spinning={true} />;
  }

  return (
    <Switch>
      <Route exact path={paths.dang_nhap} component={Login} />
      <Route exact path={paths.quen_mat_khau} component={QuenMatKhau} />
      <Route exact path={paths.khoi_phuc_mat_khau} component={KhoiPhucMatKhau} />
      <Route exact path={paths.khoi_phuc_mat_khau_wtoken} component={KhoiPhucMatKhau} />


      <Redirect exact from={paths.auth} to={paths.dang_nhap} />
      <Route component={NotFound} />
    </Switch>
  );
};

export default withRouter(AuthRoute);
