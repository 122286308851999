import { Spin } from 'antd';
import React from 'react';

const LoadingModal = ({
    loading = false,
}) => loading ?(
    <div style={{position: 'absolute', display: 'flex', top: 0,alignItems: 'center', justifyContent: 'center', width: '100%',height: '100%',zIndex:100000, backgroundColor: 'rgba(0,0,0,0.3)'}}><Spin size={500}/></div>
) : <div/>;

export default LoadingModal;
