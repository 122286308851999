import { ThemeSwitcherProvider } from "react-css-theme-switcher";

const themeKey = {
  auth: "auth",
  main: "main",
};

const themes = {
  [themeKey.auth]: `${process.env.PUBLIC_URL}/auth-theme.css`,
  [themeKey.main]: `${process.env.PUBLIC_URL}/main-theme.css`,
};

const ThemeProvider = ({ children }) => {
  return (
    <ThemeSwitcherProvider themeMap={themes} defaultTheme={themeKey.main}>
      {children}
    </ThemeSwitcherProvider>
  );
};

export default ThemeProvider;
