import { Button, Col, DatePicker, Flex, Input, Modal, Row, Select, Table, notification } from 'antd';
import React, { forwardRef, useImperativeHandle, useState } from 'react';
import AxiosService from '../../../../../common/AxiosService';
import { apis } from '../../../../../constants';
import { formatWeightText } from '../../../../../helpers';
import { CheckOutlined, CloseOutlined, ExclamationCircleOutlined, FormOutlined } from '@ant-design/icons';
import style from "./chiTietChiThiNhap.module.less";
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import dayjs from 'dayjs';
import { generateNewTypeOfSteel } from '../../../../../utils/steelMasterType';
import { appLoading, setAppProperties } from '../../../../../ducks/slices/appSlice';
import _ from 'lodash';
import { CONSIGNMENT } from '../../../../../constants/utils';

const ChiTietChiThiNhap = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    // const isView = auth.user_data.isView;
    const auth = useSelector((state) => state.auth);
    const {reloadData = () => {}} = props;
    const [isModalOpen, set_isModalOpen] = useState(false);
    const [data, set_data] = useState([])
    const [modal, contextHolder] = Modal.useModal();
    const [providerName, set_providerName] = useState('')
    const [coilId, set_coilId] = useState('')
    const [steelGrade, set_steelGrade] = useState('')
    const [importDate, set_importDate] = useState('')
    const [steelType, set_steelType] = useState('')
    const [temWeight, set_temWeight] = useState('')
    const [sizeWidth, set_sizeWidth] = useState('')
    const [sizeDepth, set_sizeDepth] = useState('')
    const [note, set_note] = useState('')
    const [producerCode, set_producerCode] = useState('')
    const [consignment, set_consignment] = useState('');
    const app = useSelector(state => state.app)

    const disableButton = (auth?.user_data?.isView === '1') ? "true" : "";
    const displayBlock = {
      display : "none"
    }
    const displayInline = {
      display : "inline-block"
    }
    
  

    useImperativeHandle(ref, () => ({
        openModal(data) {
            set_isModalOpen(true)
            initModalData(data)
            // loadRequestDetail(requestId)
        },

    }));

    const initModalData = (data) => {
        set_data(data)
        set_providerName(data.providerName)
        set_coilId(data.nsmvCoilId)
        set_steelGrade(data.steelGrade)
        set_importDate(dayjs(data.importDate, "DD/MM/YYYY"))
        let type = app.steelTypeMaster.find(item => item.label === data.steelType);
        if (!!type) {
          set_steelType(type.value)
        }

        set_temWeight(data.temWeight)
        set_sizeWidth(data.sizeWidth)
        set_sizeDepth(data.sizeDepth)
        set_note(data.note)
        set_producerCode(data.producerCode)
        set_consignment(data.consignment)
    }
   
    const handleOk = () => {
        set_isModalOpen(false);
    };
    const handleCancel = () => {
        set_isModalOpen(false);
    };

    const onRequestDelele = () => {
      modal.confirm({
        title: 'Xác nhận xóa',
        icon: <ExclamationCircleOutlined />,
        content: 'Bạn chắc chắn muốn xóa sản phẩm này?',
        okText: 'Xóa',
        okType: 'danger',
        onOk : () => onsendRequestCancel(),
        cancelText: 'Đóng',
      });
    
    }

    const onsendRequestCancel = () => {
      dispatch(appLoading(true))
      let dataRequest = {
        "id" : data.id,
      }
      AxiosService.post(apis.Url_Api_Post_HuyChiThiNhapKho, dataRequest)
      .then(res => {
        let { data } = res;
        if (!!data && data.message === "OK") {
          notification.success({message: "Xóa thành công"})
          reloadData();
          handleCancel();
          dispatch(appLoading(false))
        } else {
          notification.error({message: data.message})
          dispatch(appLoading(false))
        }
      })
      .catch(e => {
        dispatch(appLoading(false))
        notification.error({message: e.message})
      })
    }
    

    
      const requestConfirmExport = async (item) => {
        dispatch(appLoading(true))
        await createNewSteelTypes();
        let dataRequest = {
            ...data,
            "rollProducerId" : data.id,
            "coilId" : coilId,
            "steelGrade" : steelGrade,
            "importDate" : dayjs(importDate).format('DD/MM/YYYY'),
            "steelType" : !!steelType.includes('create-new-') ? steelType.split('create-new-')[1] : steelType,
            "temWeight" : Number(temWeight),
            "size" :  sizeDepth + "×" + sizeWidth,
            "providerName" : providerName,
            "producerCode" : producerCode,
            "note" : note,
            consignment
        }
        AxiosService.post(apis.Url_Api_Post_EditThongTinTonCuon, dataRequest)
        .then(res => {
          let { data } = res;
          if (!!data && data.message === "OK") {
            notification.success({message: "Sửa thông tin thành công"})
            reloadData();
            handleCancel();
            dispatch(appLoading(false))
          } else {
            notification.error({message: data.message})
            dispatch(appLoading(false))
          }
        })
        .catch(e => {
          dispatch(appLoading(false))
          notification.error({message: e.message})
        })
      }

      const requestConfirmAndImport = async (item) => {
        dispatch(appLoading(true))
        await createNewSteelTypes();
        let dataRequest = {
            "rollProducerId" : data.id,
            "coilId" : coilId,
            "steelGrade" : steelGrade,
            "importDate" : dayjs(importDate).format('DD/MM/YYYY'),
            "steelType" : !!steelType.includes('create-new-') ? steelType.split('create-new-')[1] : steelType,
            "temWeight" : Number(temWeight),
            "netWeight" : Number(temWeight),
            "size" : sizeWidth + "×" +sizeDepth,
            "providerName" : providerName,
            "producerCode" : producerCode,
            "note" : note,
            "producerCoilId": coilId,
            consignment,
            byPass: true,
            draft : "0",
            saveInStock : "1",
        }
        // console.log("Request", dataRequest)
        // return
        AxiosService.post(apis.Url_Api_Post_EditThongTinTonCuon, dataRequest)
        .then(res => {
          let { data } = res;
          if (!!data && data.message === "OK") {
            notification.success({message: "Nhập kho thành công"})
            reloadData();
            handleCancel();
            dispatch(appLoading(false))
          } else {
            notification.error({message: data.message})
            dispatch(appLoading(false))
          }
        })
        .catch(e => {
          dispatch(appLoading(false))
          notification.error({message: e.message})
        })
      }

      const createNewSteelTypes = async () => {
        let newSteelTypes = app.steelTypeMaster.filter(e => !!e.value.includes('create-new-'));
        newSteelTypes = newSteelTypes.map(e => {
          return {
            value: e.value.split("create-new-")[1],
            label: e.label
          };
        });
        await Promise.all(
          newSteelTypes.map(async e => {
            const requestData = {
              steelTypeName: e.label,
              steelTypeAcronym: e.value
            }
            try {
              await AxiosService.post(apis.Url_Api_Add_MasterData_SteelType, requestData)
            } catch (err) {
              console.log('createNewSteelTypes', err);
              notification.error({ error: err.message })
            }
          })
        )
      }

      const onSearchSteelMaster = _.debounce(function (searchTerm) {
        const isExist = app.steelTypeMaster.find(e => e.label === searchTerm.trim());
     
        if (!isExist) {
         const oldSteelTypeMaster = app.steelTypeMaster.filter(e => !e.value.includes('create-new-'));
  
         let newSteelTypeMaster = [
          ...oldSteelTypeMaster,
        ]
  
         if (!!searchTerm && searchTerm !== "") {  
           const newElement = generateNewTypeOfSteel({
             searchTerm,
             originalSteelMasterTypes: oldSteelTypeMaster,
             addedSteelTypeMaster: []
           });
           newSteelTypeMaster = !!newElement ? [...newSteelTypeMaster, { ...newElement }] : [...newSteelTypeMaster];
         }
     
         dispatch(setAppProperties({
           steelTypeMaster: [...newSteelTypeMaster]
          }))
        }
     }, 100);
  
     const onSteelTypeInputChange = (inputData) => {
      if (!inputData.includes('create-new-')) {
        dispatch(setAppProperties({
          steelTypeMaster: [
            ...app.steelTypeMaster.filter(e => {
              // lấy những loại cũ và những loại mới ở row khác
              return  !e.value.includes('create-new-');
            })
          ]
        }))
      }
      set_steelType(inputData);
     }

    return <Modal title="CHI TIẾT NHẬP KHO DỰ KIẾN" closeIcon = {false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={style['modal-container']} width={'75vw'}
    
    footer={[
        <Button key="back" onClick={handleCancel}>
          Đóng
        </Button>,
         <Button key="submit" type="danger" onClick={onRequestDelele}>
          Hủy
        </Button>,
        <Button type='primary'
          onClick={requestConfirmExport}
        >
          Lưu
        </Button>,

        < Button type='primary'
            style={disableButton ? displayBlock : displayInline}
         onClick={requestConfirmAndImport}
        >
          Lưu & Nhập
        </Button>

      ]}
    >
        <div className={style['main']}>
        <Row gutter={[10, 20]} align={'middle'}>
          <Col span={2} className={style['filter-title']}>Ngày nhập</Col>
          <Col span={4}><DatePicker format={"DD/MM/YYYY"}  className={style['filter-input']} value={importDate} onChange={(date, dateString) => {set_importDate(date)}  } /></Col>
          <Col span={2} className={style['filter-title']}>{auth.wareHouseName == 'X' ? 'Số dải' : 'Số cuộn'}</Col>
          <Col span={4}><Input className={style['filter-input']} style={{color: '#000'}} required value={coilId} onChange={({ target }) => set_coilId(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Mác thép</Col>
          <Col span={4}><Input className={style['filter-input']} required value={steelGrade} onChange={({ target }) => set_steelGrade(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Chủng loại</Col>
          <Col span={4}><Select
            className={style['filter-select']}
            onChange={onSteelTypeInputChange}
            // defaultValue={''}
            value={steelType}
            showSearch
            optionFilterProp="label"
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.steelTypeMaster.filter(item => item.value !== '')}
            onSearch={onSearchSteelMaster}
          /></Col>
          <Col span={2} className={style['filter-title']}>Loại hàng</Col>
          <Col span={4}>
            <Select
              className={style['filter-select']}
              onChange = {(value)  => set_consignment(value)}
              value={consignment}
              optionFilterProp="label"
              dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
              options={CONSIGNMENT}
            />
          </Col>
          <Col span={2} className={style['filter-title']}>Nhà cung cấp</Col>
          <Col span={4}><Input className={style['filter-input']} value={providerName} onChange={({ target }) => set_providerName(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Dày × Rộng</Col>
          <Col span={4}>
            <Flex align="center">
              <Input className={style['filter-input']} value={sizeDepth} onChange={({ target }) => set_sizeDepth(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidth} onChange={({ target }) => set_sizeWidth(target.value.replace(/[^0-9.]/g, ""))} /></Flex>
          </Col>
          <Col span={2} className={style['filter-title']}>Trọng lượng</Col>
          <Col span={4}><Input className={style['filter-input']} value={temWeight} onChange={({ target }) => set_temWeight(target.value.replace(/[^0-9.]/g, ""))} /></Col>
          <Col span={2} className={style['filter-title']}>Nhà sản xuất</Col>
          <Col span={4}><Input className={style['filter-input']} value={producerCode} onChange={({ target }) => set_producerCode(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Ghi chú</Col>
          <Col span={4}><Input className={style['filter-input']} value={note} onChange={({ target }) => set_note(target.value)} /></Col>
        </Row>
        </div>
        {contextHolder}
    </Modal>
});

export default ChiTietChiThiNhap;