import { Breadcrumb, Button, DatePicker, Row, Select, Column, Col, Input, Table, Flex, Pagination, notification } from "antd";
import { Link } from "react-router-dom";
import { apis, keys, paths } from "../../../../constants";
import style from "./taoChiThiKiemKe.module.less";
import { ArrowDownOutlined, DeleteOutlined, DownloadOutlined, FormOutlined, PlusOutlined, SearchOutlined, TagFilled } from "@ant-design/icons";
import { IconEraser } from "../../../../assets/svg";
import { useEffect, useRef } from "react";
import AxiosService from "../../../../common/AxiosService";
import { useState } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { checkEmptyData, formatWeightText } from "../../../../helpers";
import cn from 'classnames'
import dayjs from 'dayjs'
import configs from "../../../../constants/configs";
import { useDispatch, useSelector } from "react-redux";
import { appLoading } from "../../../../ducks/slices/appSlice";

const TaoChiThiKiemKe = () => {
  const [data, set_data] = useState([]);
  const [total, set_total] = useState(0);
  const [totalWeight, set_totalWeight] = useState('');

  const [providerName, set_providerName] = useState('')
  const [coilId, set_coilId] = useState('')
  const [steelGrade, set_steelGrade] = useState('')
  const [importDate, set_importDate] = useState()
  const [steelType, set_steelType] = useState('')
  const [netWeight, set_netWeight] = useState('')
  const [size, set_size] = useState('')
  const [sizeWidth, set_sizeWidth] = useState('')
  const [sizeDept, set_sizeDept] = useState('')
  const [sizeWidthTo, set_sizeWidthTo] = useState('')
  const [sizeDeptTo, set_sizeDeptTo] = useState('')
  const [currentDataRequest, set_currentDataRequest] = useState({})
  const [currentPage, set_currentPage] = useState(1);
  const [loading, set_loading] = useState(false)
  const [totalCount, set_totalCount] = useState('')

  const [pageSize, set_pageSize] = useState(20);
  const app = useSelector(state => state.app)
  const auth = useSelector(state => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {
    //reset data khi đổi kho
    initData()
  }, [auth.wareHouseName])

  const initData = () => {
    set_data([]);
    onPressClear();
    let dataRequest = {
      start: 0,
      length: 10000,
      draft: null,
      status: "0",
      providerName: null,
      steelType: null,
      rollNsmvId: null,
      steelGrade: null,
      importDate: null,
      netWeight: null,
      sizeWidth: null,
      sizeDepth: null,
      sizeWidthTo: null,
      sizeDepthTo: null,
      saveInStock: 1,
      sortRequest: {
        importDate : "desc",
        nsmvCoilId : "asc",
        steelGrade : null,
        steelType : null,
        sizeDepth : null,
        sizeWidth : null,
        netWeight : null,
        temWeight: null,
        providerName : null,
        producerCode : null,
        note : null
      },
      }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const onPressFilter = () => {
    let sizeRangeValidate = getSizeRangeValidate();
    set_currentPage(1)
    let dataRequest = {
      start: 0,
      length: 10000,
      draft: null,
      status: "0",
      consignment:null,
      providerName: checkEmptyData(providerName),
      steelType: checkEmptyData(steelType),
      rollNsmvId: checkEmptyData(coilId),
      steelGrade: checkEmptyData(steelGrade),
      importDate: !!checkEmptyData(importDate) ? dayjs(importDate).format('DD/MM/YYYY') : null,
      netWeight: checkEmptyData(netWeight),
      sizeWidth: checkEmptyData(sizeRangeValidate.sizeWidth),
      sizeDepth: checkEmptyData(sizeRangeValidate.sizeDepth),
      sizeWidthTo: checkEmptyData(sizeRangeValidate.sizeWidthTo),
      sizeDepthTo: checkEmptyData(sizeRangeValidate.sizeDepthTo),
      saveInStock: 1,
      sortRequest: {
        importDate : "desc",
        nsmvCoilId : "asc",
        steelGrade : null,
        steelType : null,
        sizeDepth : null,
        sizeWidth : null,
        netWeight : null,
        temWeight: null,
        providerName : null,
        producerCode : null,
        note : null
      }
    }
    set_currentDataRequest(dataRequest)
    requestGetData(dataRequest)
  }

  const getSizeRangeValidate = () => {
    let requestSize = {
      sizeWidth:'',
      sizeDept:'',
      sizeWidthTo:'',
      sizeDeptTo:'',
    }

    if(sizeDept == '' && sizeDeptTo != ''){
      requestSize.sizeDept = "";
      requestSize.sizeDeptTo = sizeDeptTo;
      // set_sizeDept(sizeDeptTo)
      // set_sizeDeptTo('')
    }else if(sizeDeptTo == '' && sizeDept != ''){
      requestSize.sizeDept = sizeDept;
    }else if(sizeDeptTo != '' && sizeDept != ''){
      if(Number(sizeDeptTo) < Number(sizeDept)){
        set_sizeDept(sizeDeptTo)
        set_sizeDeptTo(sizeDept)
        requestSize.sizeDept = sizeDeptTo;
        requestSize.sizeDeptTo = sizeDept;
      }else{
        requestSize.sizeDept = sizeDept;
        requestSize.sizeDeptTo = sizeDeptTo;
      }
    }else{
      //do nothing
    }

    if(sizeWidth == '' && sizeWidthTo != ''){
      requestSize.sizeWidth = "";
      requestSize.sizeWidthTo = sizeWidthTo;
      // set_sizeWidth(sizeWidthTo)
      // set_sizeWidthTo('')
    }else if(sizeWidthTo == '' && sizeWidth != ''){
      requestSize.sizeWidth = sizeWidth;
    }else if(sizeWidthTo != '' && sizeWidth != ''){
      if(Number(sizeWidthTo) < Number(sizeWidth)){
        set_sizeWidth(sizeWidthTo)
        set_sizeWidthTo(sizeWidth)
        requestSize.sizeWidth = sizeWidthTo;
        requestSize.sizeWidthTo = sizeWidth;
      }else{
        requestSize.sizeWidth = sizeWidth;
        requestSize.sizeWidthTo = sizeWidthTo;
      }
    }else{
      //do nothing
    }

    return requestSize;
  }

  const requestGetData = (dataRequest) => {
    set_loading(true)
    AxiosService.post(apis.Url_Api_Post_LayDanhSachTonKho, dataRequest)
      .then(response => {
        let { data } = response;
        console.log(data);
        if (!!data && data.message === "OK") {
        caculateTotal(data.data)
          set_data(data.data)
        }else if (data.message === keys.api_message_EMPTY) {
          set_total(0)
          set_data([])
          set_totalWeight(0)
        }
        set_loading(false)
      })
      .catch(err =>{ console.log(err);
        set_loading(false)
      })
  }

  const caculateTotal = (data) => {
    set_totalCount(data.length)
    set_totalWeight(data.reduce((prev, cur) => {
        return prev + cur?.netWeight || 0
    } , 0))
  }
  const onChangePageSize = (val) => {
    set_pageSize(val);
    set_currentPage(1);
  }

  const onChangePage = (val) => {
    set_currentPage(val)
  }

  const onPressAdd = () => {
   
      requestCreateOrderInventory()
    // history.push(paths.main_them_moi_nhap_kho);
  }

  const requestCreateOrderInventory = () => {
    dispatch(appLoading(true))
    let dataRequest = {
            "listRollcoil": [],
            "inventoryDate": moment().format("YYYY-MM-DD"),
            ...(auth.wareHouseName == "C" && {listRollCoilEntity: data}),
            ...(auth.wareHouseName == "X" && {listSplitTapeEntity: data}),
    }
    AxiosService.post(apis.Url_Api_Post_KiemKe_TaoMoiChiThi, dataRequest)
      .then(response => {
        let { data } = response;
        if (!!data && data.message === "OK") {
          requestGetNotiToken()
            notification.success({message: "Tạo chỉ thị thành công"})
            initData()
            // history.push(paths.main_danh_sach_chi_thi_kiem_ke);
        }else {
          notification.error({message: data.message})
        }
        dispatch(appLoading(false))
      })
      .catch(err => {
        console.log(err);
        dispatch(appLoading(false))
      })
  }

  const requestGetNotiToken = () => {
    let dataRequest = {
      "lstRole" : [keys.userRoles.KhoTonCuon_Request]
    }
    AxiosService.post(apis.Url_Get_ListMessageTokenByRole, dataRequest)
      .then(response => {
        let { data } = response;
        console.log(data);
        if (!!data && data.message === "OK") {
          requestSendNoti(data.data[keys.userRoles.KhoTonCuon_Request])
        }else {
        }
      })
      .catch(err => console.log(err))
  }

  const requestSendNoti = (listToken = []) => {
    let dataRequest = {
      "subject" : "NSMV STORAGE",
      "content" : "NSMV STORAGE có chỉ thị kiểm kê mới",
      "data" : {
         "message" : "chỉ thị kiểm kê mơi"
      },
      "registrationToken" : listToken.map(item => item.token)
  }
  console.log('send noti', dataRequest);
    AxiosService.post(apis.Url_Get_SendNotification, dataRequest)
      .then(response => {
        let { data } = response;
        console.log('send noti response',data);
      })
      .catch(err => console.log(err))
  }

  
  const onPressClear = () => {
    set_importDate('')
    set_providerName('')
    set_coilId('')
    set_steelGrade('')
    set_importDate('')
    set_steelType('')
    set_netWeight('')
    set_sizeWidth('')
    set_sizeDept('')
    set_sizeWidthTo('')
    set_sizeDeptTo('')
  }

  const columns = [
    {
      title: 'Ngày nhập',
      dataIndex: 'importDate',
      key: 'importDate',
      width: '115px',
      // render: (importDate, item) => moment(importDate,'MM/DD/YYYY').format('DD/MM/YYYY')
    },
    {
      title: auth.wareHouseName == 'X' ? 'Số dải' : 'Số cuộn',
      dataIndex: 'nsmvCoilId',
      key: 'nsmvCoilId',
      width: '100px',
      render: (value) => <div className={style["important-text"]}>{value}</div>
    },
    {
      title: 'Mác Thép',
      dataIndex: 'steelGrade',
      key: 'steelGrade'
    },
    {
      title: 'Chủng Loại',
      dataIndex: 'steelType',
      key: 'steelType',
      render: (value) => <div>
        {app.steelTypeMaster.find(e => e.value === value)?.label || ""}
      </div>
    },
    {
      title: <div><div>Dày x Rộng</div><div style={{paddingLeft: '18px'}}>(mm)</div></div>,
      dataIndex: 'size',
      key: 'size',
      width: '150px',
      render: (size, item) => (item.sizeDepth.toString().includes(".") ? item.sizeDepth : item.sizeDepth + ".0") + " × " + (item.sizeWidth.toString().includes(".") ? item.sizeWidth : item.sizeWidth + ".0")
    },
    {
      title: <div><div>Trọng Lượng</div><div style={{paddingLeft: '25px'}}>(Kg)</div></div>,
      dataIndex: 'temWeight',
      key: 'temWeight',
      width: '150px',
      render: (value) => formatWeightText(value)
    },
    {
      title: <div><div>Trọng Lượng Còn Lại</div><div style={{paddingLeft: '55px'}}>(Kg)</div></div>,
      dataIndex: 'netWeight',
      key: 'netWeight',
      width: '200px',
      render: (value) => <div >{formatWeightText(value)}</div>
    },
    {
      title: 'Nhà cung cấp',
      dataIndex: 'providerName',
      key: 'providerName'
    },
    {
      title: 'Nhà sản xuất',
      dataIndex: 'producerCode',
      key: 'producerCode'
    },
    {
      title: 'Ghi Chú',
      dataIndex: 'note',
      key: 'note'
    }
  
  ];

  return (
    <div className={style["main-container"]}>
      <Breadcrumb items={[{
        title: 'Kiểm kê',
      },
      {
        title: 'Tạo chỉ thị',
      }]} />

      <div className={style['main']}>
        <Row gutter={[10, 20]} align={'middle'}>
          <Col span={2} className={style['filter-title']}>Nhà cung cấp</Col>
          <Col span={4}><Input className={style['filter-input']} value={providerName} onChange={({ target }) => set_providerName(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>{auth.wareHouseName == 'X' ? 'Số dải' : 'Số cuộn'}</Col>
          <Col span={4}><Input className={style['filter-input']} value={coilId} onChange={({ target }) => set_coilId(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Mác thép</Col>
          <Col span={4}><Input className={style['filter-input']} value={steelGrade} onChange={({ target }) => set_steelGrade(target.value)} /></Col>
          <Col span={2} className={style['filter-title']}>Ngày nhập</Col>
          <Col span={4}><DatePicker  format={"DD/MM/YYYY"} className={style['filter-input']} value={importDate} onChange={(date, dateString) => set_importDate(date)} /></Col>
          <Col span={2} className={style['filter-title']}>Chủng loại</Col>
          <Col span={4}><Select
            className={style['filter-select']}
            onChange={set_steelType}
            defaultValue={''}
            showSearch
            optionFilterProp="label"
            value={steelType}
            dropdownStyle={{ borderWidth: 1, borderColor: ' rgba(0, 0, 0, 0.2)', backgroundColor: 'white' }}
            options={app.steelTypeMaster}
          /></Col>
          <Col span={2} className={style['filter-title']}>Trọng lượng</Col>
          <Col span={4}><Input className={style['filter-input']} value={netWeight} onChange={({ target }) => set_netWeight(target.value.replace(/[^0-9.]/g, ""))} /></Col>
          <Col span={2} className={style['filter-title']}>Dày × Rộng</Col>
          <Col span={4}>
          <Flex align="center">
              <Input className={style['filter-input']} value={sizeDept} onChange={({ target }) => set_sizeDept(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidth} onChange={({ target }) => set_sizeWidth(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>

              <Flex align="center" justify='space-around'>
              <ArrowDownOutlined />
              <ArrowDownOutlined />
              </Flex>

              <Flex align="center">
              <Input className={style['filter-input']} value={sizeDeptTo} onChange={({ target }) => set_sizeDeptTo(target.value.replace(/[^0-9.]/g, ""))} />
              <div style={{ marginLeft: '10px', marginRight: '10px' }}>×</div>
              <Input className={style['filter-input']} value={sizeWidthTo} onChange={({ target }) => set_sizeWidthTo(target.value.replace(/[^0-9.]/g, ""))} />
              </Flex>
          </Col>
        </Row>

        <Row gutter={60} align={'middle'} justify={'center'} style={{ marginTop: '20px' }}>
          <Col ><Button onClick={onPressFilter} icon={<SearchOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tìm kiếm</Button></Col>
          <Col ><Button onClick={onPressClear} icon={<IconEraser style={{ height: '16px', width: '16px' }} />} className={style['filter-button']}>Xoá</Button></Col>
          {/* <Col ><Button icon={<DownloadOutlined style={{ fontSize: '16px', color: 'white' }} />} className={style['filter-button']}>Tải xuống</Button></Col> */}
        </Row>

        <Row style={{ marginTop: '40px' }} align={'middle'} justify={'space-between'}>
          <Col flex={'auto'}>
            <Row align={'middle'}>
              <Col >Tổng số: {totalCount}</Col>
              <Col style={{marginLeft: '80px'}}>Tổng trọng lượng: {formatWeightText(totalWeight)} Kg</Col>
            </Row>
          </Col>


          <Col><Button disabled = {data.length == 0} className={style['add-button']} onClick={onPressAdd}>Tạo chỉ thị</Button></Col>
        </Row>
        <Table columns={columns} dataSource={data.slice((currentPage - 1)*pageSize, currentPage*pageSize)} className={style['table']} rowKey="id" pagination={{ position: ['none', 'none'], pageSize: pageSize }}/>
        <Row justify={'end'} className={style['pagination']}>
          <Pagination
            locale={{ items_per_page: '' }}
            pageSizeOptions={[5, 10, 20]}
            className={style["pagination-bar"]}
            pageSize={pageSize}
            hideOnSinglePage
            current={currentPage}
            onChange={(index) => onChangePage(index)}
            defaultCurrent={1}
            total={totalCount}
            showSizeChanger = {false}
          />
          <Select value={pageSize} defaultValue={pageSize} options={configs.PageSizes} onChange={onChangePageSize} style={{ marginLeft: '10px' }} />
        </Row>
      </div>
    </div>
  );
};

export default TaoChiThiKiemKe;


