import { HashRouter, Redirect, Route, Switch } from "react-router-dom";
import { paths } from "../constants";
import { NotFound } from "../pages/common";
import AuthRoute from "./AuthRoute";
import MainRoute from "./MainRoute";
import { PrivateRoute, PublicRoute } from "./ConfigRoutes";

const RootRoute = () => {
  return (
    <HashRouter>
      <Switch>
        <PublicRoute
          path={paths.auth}
          component={AuthRoute}
        />
        <PrivateRoute
          path={paths.main}
          component={MainRoute}
        />

        <Redirect exact from={paths.root} to={paths.main} />
        <Route component={NotFound} />
      </Switch>
    </HashRouter>
  );
};

export default RootRoute;
