export const ERROR_MSG = {
    NOT_FOUND: 'Không tìm thấy bản ghi',
    NOT_FOUND_USER: 'Không tìm thấy user',
    KEY_EXIST: 'Bản ghi đã tồn tại',
    INVALID_DATA: 'Tham số không hợp lệ',
    USERNAME_OR_PASSWORD_INCORRECT: 'Tên đăng nhập/mật khẩu không đúng',
    USER_NOT_LOGIN: 'User chưa đăng nhập',
    NOT_SAVE_IN_STOCK: 'Không có cuộn trong kho',
    PULL_ROLL_DELETED: 'Mẫu kéo thử không tồn tại',
};
