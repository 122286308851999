import popup from "./popup.module.less";
import cn from "classnames";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { ClickOutside } from "../../../hooks";

/**
 * @author binhdq
 * 
 * @description Export setVisible cho component cha sử dụng
 */
const PopupV2 = forwardRef(({ children, content = <></>, className, popupClassName }, ref) => {
  const [visible, setVisible] = useState(false);
  useImperativeHandle(ref, ()=>({
    close: () => setVisible(false)
  }))

  const thisRef = useRef();
  ClickOutside(thisRef, () => setVisible(false));

  return (
    <div className={cn(popup["container"], className)} ref={thisRef}>
      <div onClick={() => setVisible((val) => !val)}>{children}</div>
      <div
        className={cn(
          popup["popup"],
          visible && popup["visible"],
          popupClassName
        )}
      >
        {content}
      </div>
    </div>
  );
});

export default PopupV2;
