const jp = {
  Language_label: "日本語",
  Common_ViewMore: "もっと見る",
  Common_Commpany_Address: " Km 7、国道 18 号線、Que Vo 工業団地、Phuong Lieu、Que Vo、Bac Ninh、Vietnam ",
  Common_HNCommpany_Address: "ICON4 ビル17階、243A De La Thanh、Lang Thuong 、Dong Da、Hanoi、Vietnam",
  Common_BNCommpany_Address: " Km 7、国道 18 号線、Que Vo 工業団地、Phuong Lieu、Que Vo、Bac Ninh、Vietnam ",
  Common_Tel: "電話番号: ",
  Common_Fax: "FAX: ",
  Common_Address: "住所: ",
  Common_Page: 'ページ',
  Common_Subject: '件名:',
  Common_Name: '名前:',
  Common_Email: 'メール:',
  Common_Phone: '電話番号:',
  Common_Mobile: '携帯電話:',
  Common_Message: 'メッセージ:',
  Common_Copyright: " Copyright Nippon Steel Metal Products Vietnam All Rights Resereved",
  Common_Upload: "アップロード",
  Common_Show: "表示",
  Common_Hidden: "閉じる",
  Common_Done: '終わり',
  Common_Cancel: 'キャンセル',
  Common_Source: '引用元',
  Common_Yes: 'はい',
  Common_No: 'いいえ',

  Footer_HeadOffice: "本社＆工場",
  Footer_HNOffice: "ハノイ事務所",

  Site_Banner_title: "本社・支店",
  Site_AboutManufacturuing_title: "日鉄建材株式会社について",
  Site_HaNoi: "Hanoi",
  Site_BacNinh: "Bac Ninh",
  Site_AboutRepresentOffice_title: "日鉄建材ベトナム有限会社について",
  Site_Manufacturuing_title: "本社＆工場",
  Site_RepresentOffice_title: " Hanoi事務所",
  Header_Button_TopPage: "トップページ",
  Header_Button_Introduct: "企業情報",
  Header_Button_Products: "取扱商品",
  Header_Button_Recruitment: "採用",
  Header_Button_Contact: "お問い合わせ",
  Header_Admin_Button_Manager: "Manage",
  Header_Admin_Button_News: "News",
  Header_Admin_Button_Product: "Product",
  Header_Admin_Button_Recruitment: "Recruitment",
  Toppage_title: "トップページ",
  Toppage_Introduction_title: "企業紹介",
  Toppage_company_introduction_part_1: "NSMVはベトナムにおいて、日本と同様の高品質な鋼管製品及び鉄鋼加工品（型枠、足場等）を市場に適切な値段で提供することを目的として設立されました。",
  Toppage_company_introduction_part_2: "創業以来、生産技術、顧客サービス等のノウハウを堅実に積み上げ、NSMVはベトナムおよび東南アジアにおける建材製造分野のリーディングカンパニーになる事を目指しています。",
  Toppage_navigate_AboutUs: "企業情報",
  Toppage_navigate_Project: "プロジェクト",
  Toppage_navigate_Sites: "本社・支店",
  Toppage_navigate_Recuitment: "採用",
  Toppage_nsmvNews_title: "トピックス",
  Toppage_industryNews_title: "業界に関する情報",
  Toppage_cultuteNews_title: "企業ニュース",
  Toppage_Product_title: "取扱商品",
  Toppage_Cerificate_title: "品質基準証明書",
  Toppage_Customer_title: "関連会社",
  Toppage_catalog: "カタログダウンロード",

  Project_Banner_title: "プロジェクト",
  Project_Segment_NationProject: "国内プロジェクト",
  Project_Segment_OverseaProject: "海外プロジェクト",
  Project_DienMatTroiHauGiang: ' Hau Giang太陽光発電',
  Project_NhietDienNghiSon: " Nghi Son 火力発電、Thanh Hoa",
  Project_GiengChimLachChay: " Lach Huyen港のケーソン、Hai Phong ",
  Project_MayTapTheDuc: '健康器具',
  Project_NhaMayNuocThaiHoaLac: ' Hoa Lac高工業団地下水処理場、Hanoi ',
  Project_LandMark81: 'Landmark 81、Ho Chi Minh',
  Project_DienGioDongHai: ' Dong Hai 1 風力発電所、Bac Lieu ',
  Project_CaoTocDaNang: ' Da Nang ～Quang Ngai 高速道路',
  Project_CauHoangVanThu: ' Hoang Van Thu橋、Hai Phong ',
  Project_CauDongTru: ' Dong Tru橋、Dong Anh、Hanoi ',
  Project_CauVanUc: ' Van Uc高架橋、Tien Lang、Hai Phong ',
  Project_NhietDienMongDuong: ' Mong Duong 2火力発電所、Cam Pha、Quang Ninh',
  Project_MelodyResident: ' Melody Residencesマンション、Hanoi ',
  Project_LGHaiPhong: ' LGプロジェクト、Cat Hai、Hai Phong ',
  Project_QuocLo45: '南北高速道路：Mai Son～国道45号線',
  Project_Dhaka: ' Dhaka 都市鉄道橋、Bangladesh',
  Project_Selabam: ' Selabam水力発電所、Laos ',

  AboutUS_Banner_title: "企業情報",
  AboutUs_GeneralDirector_title: "社長",
  AboutUs_GeneralDirector_name: "石川 琢文",
  AboutUs_NSMP_Content1: "<b>日鉄建材株式会社(NSMP)</b>：日本製鉄グループの中核企業で、建築・土木分野における鉄鋼製品を中心とした建材総合メーカーです。",
  AboutUs_NSMP_Content2: "<b>設立日</b>: 1973年4月20日",
  AboutUs_NSMP_Content3: "<b>売上高</b>: 約1,330億円/年（連結2021年度実績）",
  AboutUs_NSMP_Content4: "<b>資本金</b>: 59億1250万円",
  AboutUs_NSMP_Content5: "<b>総従業員数</b>: 1,614名（連結 2022年3月31日現在）",
  AboutUs_NSMP_Link: "NSMPのホームページへ",
  AboutUS_NSMV_Content1: "<b>日鉄建材ベトナム有限会社（NSMV）</b>はベトナムの建設及び輸送インフラ分野に幅広い鉄鋼製品の製造・加工を手掛ける大手企業の一つです。バクニン省Que Vo工業団地に工場・オフィスを、またハノイにもオフィスを構えており、ベトナム国内あらゆる都市に高品質の製品をお届けしています。",
  AboutUS_NSMV_Content2: "<b>NSMV</b>は、主に黒皮鋼管（角鋼管・丸鋼管）、楕円鋼管、コンクリート型枠、建設用足場など多様な鉄鋼製品の製造を行っています。",
  AboutUS_NSMV_Content3: "経験豊富で専門性の高いスタッフとエンジニアを有しており、当社製品の施工指導を含め、お客様のニーズに常に全力でお応えいたします。", 
  AboutUS_NSMV_Content4: "常に技術の先進性・革新性を追求し、付加価値を創造し続けることで、社会の発展と人々の暮らしに貢献します。",
  AboutUS_NSMV_Content5: "<b>NSMV</b>はこれからも信頼されるものづくりと公正かつ透明な経営を行うことで、国内及び海外のお客様から信頼いただけるパートナーであり続けると共に、ビジネスの発展・拡大のためにお客様と共に歩んでまいります。",
  Product_Banner_title: '取扱商品',
  Product_Contact: 'お問い合わせ',
  Recruitment_Banner_title: '採用',
  Recruitment_LifeAtOffice: 'オフィスの様子',
  Recruitment_LifeAtFactory: '工場の様子',
  Recruitment_DirectorMessage1: '',
  Recruitment_DirectorMessage2: 'トップメッセージ',
  Recuitment_Content: `NSMVのホームページにアクセスしていただき、誠にありがとうございます。<br/><br/>
  私たちはダイナミックで革新的な企業であり、ベトナムと日本の文化を理解し尊重し合うことを第一に、社会インフラを支え人々の安心・安全を守るために世界規模で事業を展開しています。<br/> 
  そのような私たちの価値観とビジョンを共有してもらえる熱意ある有能な人材を求めています。<br/>
  我々の一員として人々の生活に価値ある影響を提供し続けるプロジェクトに参画していただく機会がここにあります。<br/>
  日本を含むさまざまな場所でエキサイティングなキャリアの機会を幅広く提供しています。また、専門的な知識を学び自らを成長させられるチャンスがあります。社交的で相互協力を惜しまない素晴らしい職場環境にアクセスすることができます。<br/>
  NSMVは、適正な報酬と優れた福利厚生制度、そして最も重要なこととして自己実現のためのさまざまな機会を社員の一人一人に提供することをモットーにしています。<br/>
  熱意ある皆さんからの応募をお待ちしております。<br/>`,
  Recuitment_Button: '採用情報<br> Click >> <span style="color: #f6a447">HERE</span><<',
  Contact_Banner_title: 'お問い合わせ',
  Contact_Content: '当社の製品またはサービスに関するご質問、ご意見、またはフィード バックをお待ちしております。 以下のフォームにご記入ください。迅速にご連絡致します。',

  // Contact_Banner_title: 'お問い合わせ',
  // Contact_Content: '当社の製品はサービスに関するご質問、ご意見、またはフィードバックをお待ちしております。 以下のフォームにご記入ください。できるだけ早くご連絡いたします。',
  Contact_BnOffice: '本社＆製造所',
  Contact_HnOffice: 'ハノイ事務所',
  Contact_ContactUs: 'お問い合わせ',
  Contact_Submit: '送信',
  Main_Product: '主要商品',
  New_Tech_Product: 'その他',
  Design_Product: '設計事業',
  IT_Product: 'IT事業',
  Admin_Product_Title_ProductName: "商品名",
  Admin_Product_Title_ProductDetail: "商品説明",
  Admin_Product_Title_Contact: "お問い合わせ",
  Admin_Product_Title_Standards: "規格",
  Admin_Product_Title_Image: "商品画像",
  Admin_Product_Button_AddStandard: "規格保存",
  Admin_Product_Button_SaveStandard: "規格保存",
  Admin_Product_Button_Preview: "プレビュー",
  Admin_Product_Button_Submit: "作成",
  Admin_Product_Button_Save: "保存",
  Admin_News_Title_Title: "タイトル",
  Admin_News_Title_Summary: "要約",
  Admin_News_Button_Upload: "アップロード",
  Admin_News_Button_Save: "保存",
  Admin_News_Button_Preview: "プレビュー",
  Admin_News_Image_Type_Fill: "Fill",
  Admin_News_Image_Type_Contain: "Contain",
  Admin_News_Image_Type_Cover: "Cover",
  News_Category_Culture: "企業ニュース",
  News_Category_Nsmv: "トピックス",
  News_Category_Industy: "業界に関する情報",
  News_Category_All: "ALL",
  Admin_Manage_Title_News: "News",
  Admin_Manage_Title_Product: "取扱商品",
  Admin_Manage_DeleteNews_Title: "ニュース削除",
  Admin_Manage_DeleteNews_Confirm: "ニュースを削除しますか？",
  Admin_Manage_DeleteProduct_Title: "商品削除",
  Admin_Manage_DeleteProduct_Confirm: "商品を削除しますか？",

  Admin_Recuitment_Title_Intro: " はじめに",
  Admin_Recuitment_Title_FactoryImages: " 工場画像",
  Admin_Recuitment_Title_OfficeImages: " オフィス画像",
  Admin_Recuitment_Title_RecruitmentLinks: " 採用リンク",
  Admin_Recuitment_Button_Save: "保存",
  Admin_Recuitment_Title_Link: " リンク",
  Admin_Recuitment_PlaceholderLink: " リンクを入力してください",
  Slate_Placeholder: "内容を入力してください",


  Api_Message_NOT_LOGIN: "ログインが必要です",
  Api_Message_INVALID_PARAM: "不完全なデータ",
  Api_Message_OK: "成功しました",
  Api_Message_EMPTY: "データが存在していません",
  Api_Message_NOT_FOUND: "データが見つかりません",
  Api_Message_UPDATED: "更新が完了しました",
  pi_Message_REGISTER: "登録に成功しました",
  Api_Message_INVALID_FILE: "このファイル形式は、サポートされていません",
  Api_Message_INVALID_FILE_CULTURE: "企業ニュース項目にはプロフィール写真が必要です",
  Api_Message_Error: "エラーが発生しました。もう一度やり直してください",
  Api_Message_Require_ContactName: "名前が必要です",
  Api_Message_Require_ContactPhone: "電話番号が必要です",
  Api_Message_Require_ContactEmail: "Eメールが必要です",
  Api_Message_Require_Image: "写真のアップロードが必要です",
  Api_Message_Require_Standard: "少なくとも一つの基準を追加する必要があります",
  Api_Message_Require_StandardName: "基準のタイトルが必要です",
  Api_Message_Require_StandardDetail: "基準の内容が必要です",

  Contact_MailForm_Error_Subject: "メールの件名を入力してください",
  Contact_MailForm_Error_Name: "あなたの名前を入力してください",
  Contact_MailForm_Error_Email: "あなたのメールを入力してください",
  Contact_MailForm_Error_Phone: "あなたの電話番号を入力してください",
  Contact_MailForm_Success_Message : "ありがとうございました。\nメッセージが発送されました！"



};

export default jp;
