import { produce } from 'immer';

/**
 * Transform data yêu cầu băng xẻ từ nest array -> array flat để render table
 */
export const flattenRequest = requestObj => {
    var newArr = [];
    //console.log('Before transform', requestObj);
    for (const [indexB, block] of requestObj.entries()) {
        const { workOrderDate, id: blockId } = block;
        for (const [indexC, cuon] of block.lstBlock.entries()) {
            const {
                id: id_cuon,
                nsmvCoilId,
                rollId,
                no,
                sizeThickness,
                sizeWidth,
                type,
                nameCustomer,
                positionRoll1,
                positionRoll2,
                md,
                sizeWidthReal,
                weight,
                numberSplit,
                sizeWidthVia,
                totalVia,
                ratioTrash,
                mass,
            } = cuon;
            for (const [rowIndex, xe] of cuon.lstStandard.entries()) {
                const { thicknessMin, thicknessProduct, sizeWidthTape, typeProduct, stripNumber, totalWidth, weightCal, note, id: id_xe } = xe;
                const newId = `${workOrderDate}-${rollId}-${id_xe || 0}`;
                newArr.push({
                    id: newId,
                    workOrderDate,

                    id_cuon,
                    rollId,
                    nsmvCoilId,
                    no,
                    sizeThickness,
                    sizeWidth,
                    type,
                    nameCustomer,
                    positionRoll1,
                    positionRoll2,
                    md,
                    sizeWidthReal,
                    weight,
                    numberSplit,
                    sizeWidthVia,
                    totalVia,
                    ratioTrash,
                    mass,

                    id_xe,
                    thicknessMin,
                    thicknessProduct,
                    sizeWidthTape,
                    typeProduct,
                    stripNumber,
                    totalWidth,
                    weightCal,
                    note,
                    hide: true,
                    hideNgayLenh: true,
                    ...(rowIndex == 0 && {
                        hide: false,
                    }),
                    ...(rowIndex == 0 && indexC == 0 && { hideNgayLenh: false }),
                });
            }
        }
    }
    //console.log('After transform', newArr);
    return newArr;
};

/**
 * Sau khi edit bảng, transform data từ array flat thành nest array để submit lên server
 */
export const buildRequest = source => {
    //console.log('SOurce arr', source);
    var newArr = source.reduce((prev, next) => {
        const [idBlock, idCuon, idXe] = next.id.split('-');
        const blockInfo = { workOrderDate: next.workOrderDate };
        const cuonInfo = {
            rollId: next.rollId,
            no: next.no,
            sizeThickness: next.sizeThickness,
            sizeWidth: next.sizeWidth,
            type: next.type,
            nameCustomer: next.nameCustomer,
            positionRoll1: next.positionRoll1,
            positionRoll2: next.positionRoll2,
            md: next.md,
            sizeWidthReal: next.sizeWidthReal,
            weight: next.weight,
            numberSplit: next.numberSplit,
            sizeWidthVia: next.sizeWidthVia,
            totalVia: next.totalVia,
            ratioTrash: next.ratioTrash,
            mass: next.mass,
            nsmvCoilId: next.nsmvCoilId,
        };
        const xeInfo = {
            thicknessMin: next.thicknessMin,
            thicknessProduct: next.thicknessProduct,
            sizeWidthTape: next.sizeWidthTape,
            typeProduct: next.typeProduct,
            stripNumber: next.stripNumber,
            totalWidth: next.totalWidth,
            weightCal: next.weightCal,
            note: next.note,
        };

        return produce(prev, draft => {
            var iBlock = draft.findIndex(i => i.workOrderDate == next.workOrderDate);
            if (iBlock == -1) {
                draft.push({
                    id: null,
                    ...blockInfo,
                    lstBlock: [{ ...cuonInfo, id: null, lstStandard: [xeInfo] }],
                });
            } else {
                var iCuon = draft[iBlock].lstBlock.findIndex(i => i.rollId == next.rollId);
                if (iCuon == -1) {
                    draft[iBlock].lstBlock.push({
                        id: null,
                        ...cuonInfo,
                        lstStandard: [xeInfo],
                    });
                } else {
                    draft[iBlock].lstBlock[iCuon].lstStandard.push(xeInfo);
                }
            }
        });
    }, []);

    //console.log('New request transform', newArr);
    return newArr;
};
