import { Button, Col,Input, Modal, Row, notification } from 'antd';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';
import AxiosService from '../../../../../common/AxiosService';
import { apis } from '../../../../../constants';
import { CloseOutlined } from '@ant-design/icons';
import style from "./xetDuyetChiThiNhap.module.less";
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { appLoading } from '../../../../../ducks/slices/appSlice';
import { isValidEmail } from '../../../../../utils/validate';

const XetDuyetChiThiNhap = forwardRef((props, ref) => {

    const {reloadData = () => {}} = props;

    const [isModalOpen, set_isModalOpen] = useState(false);
    const [data, set_data] = useState([])
    // const [listMail, set_listMail] = useState([{mail: ''}])

    //danh sách toàn bộ email server trả về
    const allMail = useSelector(state => state.app.listEmailUser)
    // danh sách mail sẽ được gửi đi
    const [mailList ,setList] = useState([]);
    // input hiện tại 
    const [currentMail, setCurrentMail] = useState('');
    const inputRef = useRef()
    // danh sách mail được suggest, hiển thị trong dropdown
    const [suggest, setSuggest] = useState([]);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [errorText ,setError] = useState('');
   
    const removeMail = (mail)=> {
      setList(prev => prev.filter(item => item != mail));
      inputRef.current?.focus();
    }

    const onMailTextChanged = (mailText) => {
      var mail = mailText.replace(' ','')
      setCurrentMail(mail);
      if(mail.length >= 1) {
        setSuggest(allMail.filter(i => i.toLowerCase().includes(mail.toLowerCase()) && !mailList.includes(i)).slice(0,4))
      }
      else if(mail.length == 0) {
        setSuggest([]);
      }
    }

    //chọn suggest email từ dropdown
    const selectMailFromSuggest = (mail) => {
      setError('');
      setList(prev => prev.concat(mail));
      setSuggest([]);
      setCurrentMail('');
      setHoverIndex(-1);
      inputRef.current?.focus();
    }

    /**
     * Handle key down event khi đang focus input
     */
    const onSubmitText = (event) => {
      //nếu option đang hover => enter sẽ tự động nhập email của option vào list
      if(event.key === 'Enter' && hoverIndex > -1) {
        selectMailFromSuggest(suggest[hoverIndex])
      }
      //khi không hover, enter hoặc spacebar sẽ nhập email từ input vào list
      else if (event.key === 'Enter' || event.key == " ") {
        if(isValidEmail(currentMail)) {
          if(mailList.includes(currentMail)) {
            setError("Email bị trùng lặp")
          }
          else selectMailFromSuggest(currentMail)    
        }
        else setError("Email không hợp lệ!")
      }
      //xoá email có trong list = backspace
      else if(event.key == 'Backspace' && currentMail == '') {
        setList(list => list.length > 0 ? list.filter((_,index)=> index != list.length - 1) : list)
      }
      //chọn các option trong dropdown = các phím arrow
      else if(['ArrowDown','ArrowUp'].includes(event.key)) {
        var suggestLength = suggest.length;
        let newIndex = hoverIndex;
        if(hoverIndex == -1) newIndex = 0
        else {
          if(event.key == 'ArrowUp') newIndex = (suggestLength + hoverIndex - 1) % suggestLength
          else newIndex = (hoverIndex + 1) % suggestLength
        }
        // console.log("New index", newIndex)
        setHoverIndex(newIndex)
      }
    }

    const app = useSelector(state => state.app)
    const dispatch = useDispatch()
  
    useImperativeHandle(ref, () => ({
        openModal(data) {
            set_isModalOpen(true);
            initModalData(data);
            // loadRequestDetail(requestId)
        }
    }));
    useEffect(()=>{
      if(isModalOpen) {
        inputRef.current?.focus()
      }
    },[isModalOpen])

    const initModalData = (data) => {
        console.log(data);
        set_data(data)
    }
   
    const handleOk = () => {
        set_isModalOpen(false);
    };
    const handleCancel = () => {
        set_isModalOpen(false);
        setCurrentMail('');
        setList([]);
        setSuggest([]);
        setError('')
    };

    // const validateMailForm = () => {
    //     // let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g;
    //     // let regex = /^[\w\-\.]+@([\w-]+\.)+[\w-]{2,}$/gm;
        
    //     let regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //     let validatedList = listMail.map(item => ({...item, isError: !regex.test(item.mail) && item.mail !== ''}))
    //     set_listMail(validatedList)
    //     console.log(validatedList);
    //     if(validatedList.some(item => item.isError)){
    //         return false
    //     }else{
    //         return true
    //     }
    // }

    
      const requestConfirm = (item) => {
        if(mailList.length == 0) {
          setError("Nhập ít nhất 1 email!");
          return
        }
        dispatch(appLoading(true))
        let dataRequest = {
            "lstRollId" : data.map(item => item.id),
            "isAccess" : true,
            "listMail" : mailList
        }
        console.log(dataRequest, mailList);
        AxiosService.post(apis.Url_Api_Post_PheDuyetChiThiXuatKho, dataRequest)
        .then(res => {
          let { data } = res;
          if (!!data && data.message === "OK") {
            notification.success({message: "Phê duyệt thành công"})
            reloadData();
            handleCancel();
            dispatch(appLoading(false))
          } else {
            notification.error({message: data.message})
            dispatch(appLoading(false))
          }
        })
        .catch(e => {
          dispatch(appLoading(false))
          notification.error({message: e.message})
        })
      }
      console.log('current mail', currentMail)
    return <Modal
     title="GỬI MAIL ĐẾN BỘ PHẬN LIÊN QUAN" 
     closeIcon = {false} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} className={style['modal-container']} width={'840px'}
    
    footer={[
        <Button key="back" onClick={handleCancel}>
          Đóng
        </Button>,
        <Button type='primary'
          onClick={requestConfirm}
        >
          Duyệt & Gửi
        </Button>
      ]}
    >
        <div className={style['main']}>
        <Row align={'top'} style={{marginTop: '10px'}}>
          <Col span={4} className={style['filter-title']}>Người nhận:</Col>
          <Col span={19}>
          <div className={style['filter-wrapper']}>
            {
              mailList.map((item,index) => (
                <Button key={item + index} 
                        style={{backgroundColor:'#2c3782'}} 
                        type="primary" iconPosition={"end"} 
                        icon={<CloseOutlined style={{color:'white', fontSize:'10px'}} size={'10px'}/>} 
                        className={style["chip"]}
                        onClick={()=> removeMail(item)}>
                        
                {item}
              </Button>
              ))
            }
             <Input className={style['filter-input']} style={{color: '#000'}} required 
                    value={currentMail}
                    onChange={({ target }) => {
                      onMailTextChanged(target.value)
                    }} 
                    onKeyDown={onSubmitText}
                    ref={inputRef}/>   
              {/* <Input className={style['filter-input']} style={{color: '#000'}} /> */}
          </div>
          {
            suggest.length > 0 &&  <div className={style['dropdown']}>
            {
              suggest.map((item,index) => (
                <Row key={item + index} 
                     className={cn(style["dropdown-item"], hoverIndex == index && style["dropdown-item-hover"])} 
                     onClick={()=> selectMailFromSuggest(item)} 
                     onMouseEnter={()=> setHoverIndex(index)} 
                     onMouseLeave={()=> setHoverIndex(-1)}>
                  {item}
                </Row>
              ))
            }
          </div>
          }
          {!!errorText && <div className={style["error-text"]}>{errorText}</div>}
          </Col>
        </Row>
        
        </div>
    </Modal>
});

export default XetDuyetChiThiNhap;
