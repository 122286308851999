import { Modal, Row, Col, Input, Button, DatePicker, Flex, Select, notification } from 'antd';
import React, { forwardRef, useState, useImperativeHandle } from 'react'
import style from "./chiTietXuatKho.module.less";
import dayjs from 'dayjs';
import { useSelector } from 'react-redux';
import AxiosService from '../../../../../common/AxiosService';
import { apis } from '../../../../../constants';
import moment from 'moment';

const ChiTietXuatKho = forwardRef((props, ref) => {
    const [isModalOpen, set_isModalOpen] = useState(false);
    const [modal, contextHolder] = Modal.useModal();

    const [data, set_data] = useState([])
    const [deliverDate, set_deliverDate] = useState('')
    const [note, set_note] = useState('')
    const [orderBodyId, set_orderBodyId] = useState('')
    const [rollId, set_rollId] = useState('')
    const [importDate , set_importDate] = useState('') 

    const app = useSelector(state => state.app)
    const { reloadData = () => { } } = props;

    useImperativeHandle(ref, () => ({
        openModal(data) {
            set_isModalOpen(true)
            initModalData(data)
        }
    }));

    const initModalData = (data) => {
        set_data(data)
        set_deliverDate(dayjs(data.deliverDate, "DD/MM/YYYY"))
        set_note(data.note)
        set_orderBodyId(data.orderBodyId)
        set_rollId(data.rollId)
        set_importDate(data.importDate)
    }

    const handleOk = () => {
        set_isModalOpen(false)
    }

    const handleCancel = () => {
        set_isModalOpen(false);
        set_deliverDate('')
        set_note('')
        set_importDate('')
        set_orderBodyId("")
        set_rollId("")
    }

    const disabledDate = (current) => {
        return current && current <= moment(importDate, 'DD/MM/YYYY');
    };
      
    const requestEditCoilData = async () => {
        let dataRequest = {
            orderBodyId,
            rollId,
            deliverDate: dayjs(deliverDate).format('DD/MM/YYYY'),
            note: note
        }
        AxiosService.post(apis.Url_Api_Post_Update_Deliver_Date, dataRequest)
            .then(res => {
                let { data } = res;
                if (!!data && data.message === "OK") {
                    notification.success({ message: "Sửa thông tin thành công" })
                    reloadData();
                    handleCancel();
                } else {
                    notification.error({ message: data.message })
                }
            })
            .catch(e => notification.error({ message: e.message }))
    }

    return (
        <Modal title="CHỈNH SỬA XUẤT KHO"
            closeIcon={false}
            open={isModalOpen}
            onOk={handleOk}
            onClose={handleCancel}
            className={style['modal-container']}
            width={'60vw'}
            footer={[
            ]}
        >
            <div className={style['main']}>
                <Row gutter={[26,26]} style={{ marginTop: '20px' }}>
                    <Col span={12}>
                        <Row >
                            <Col span={4} className={style['filter-title']} required>Ngày Xuất <span className={style["required"]}>*</span></Col>
                            <Col span={19}>
                                <DatePicker format={"DD/MM/YYYY"} className={style['filter-input']} value={deliverDate} onChange={(date, dateString) => { set_deliverDate(date) }} /></Col>
                        </Row>
                    </Col>

                    <Col span={12}>
                        <Row >
                            <Col span={3} className={style['filter-title']}>Ghi chú </Col>
                            <Col span={20}><Input.TextArea className={style['filter-input']} value={note} onChange={({ target }) => set_note(target.value)} /></Col>
                        </Row>
                    </Col>
                </Row>
                <Row align={'middle'} style={{ marginTop: '20px' }} gutter={[20]} justify={'end'}>
                    <Col>
                        <Button key="back" onClick={handleCancel}>Đóng</Button>
                    </Col>
                    <Col>
                        <Button type='primary' disabled={deliverDate === null} onClick={requestEditCoilData}>Lưu</Button>
                    </Col>
                    {contextHolder}
                </Row>
            </div>
        </Modal>
    )
})

export default ChiTietXuatKho