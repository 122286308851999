/* eslint-disable no-loop-func */
import { toLowerCaseNonAccentVietnamese } from "./vietnameseString";

export const generateNewTypeOfSteel = ({
  searchTerm,
  originalSteelMasterTypes,
  addedSteelTypeMaster
}) => {
  if (!!searchTerm && searchTerm !== "") {
    const removeVnAccent = toLowerCaseNonAccentVietnamese(searchTerm);
    const removeWhiteSpace = removeVnAccent.trim().split(" ");
    const firstCharacters = removeWhiteSpace.map((e) => e[0].toUpperCase());
    let newValue = firstCharacters.reduce((pre, curr) => {
      return `${pre}${curr}`;
    }, "");
    
    let isExistInOriginal = originalSteelMasterTypes.find((e) => e.value === newValue);
    let isExistInAdded = addedSteelTypeMaster.find((e) => e.value === `create-new-${newValue}`);
    let isDuplicateValue = !!isExistInOriginal || !!isExistInAdded;

    let index = 0;
    // maximum loop: 100
    while (!!isDuplicateValue && index < 100) {
      index++;
      newValue = `${newValue}${index}`;
      isExistInOriginal = originalSteelMasterTypes.find((e) => e.value === newValue);
      isExistInAdded = addedSteelTypeMaster.find((e) => e.value === `create-new-${newValue}`);
      isDuplicateValue = !!isExistInOriginal || !!isExistInAdded;
    }

    const newElement = {
      label: searchTerm.trim(),
      value: `create-new-${newValue}`,
    };

    return newElement;
  }
  return null;
};
