// import reportWebVitals from "./reportWebVitals";
import RootRoute from "./routes/RootRoute";
import { createRoot } from 'react-dom/client';
import { Provider } from "react-redux";
import store from "./ducks/configStore";
import viVN from "antd/lib/locale/vi_VN";
import enUS from "antd/lib/locale/en_US";
import jpJP from "antd/lib/locale/ja_JP";
import { ConfigProvider } from "antd";
import { languages } from "./i18n";
// import "antd/dist/antd.css";
import "moment/locale/vi";
import moment from "moment";
import ThemeProvider from "./theme/ThemeProvider";

moment.locale("vi");


const get_locale = () => {
  switch (localStorage.getItem("i18nextLng")) {
    case languages.tieng_anh:
      return enUS;
    case languages.tieng_viet:
      return viVN;
    case languages.tieng_nhat:
      return jpJP;

    default:
      return viVN;
  }
}



const container = document.getElementById('root');
const root = createRoot(container); // createRoot(container!) if you use TypeScript
root.render(
  <Provider store={store}>
    <ConfigProvider locale={get_locale()} >
      <ThemeProvider>
        <RootRoute />
      </ThemeProvider>
    </ConfigProvider>
  </Provider>
);
