import Axios from "axios";
import { keys } from "../constants";
import { HLog, localGet } from "../helpers";
import { ERROR_MSG } from "./mapping";
class AxiosService {
  constructor() {
    const instance = Axios.create();
    instance.interceptors.response.use(this.handleSucess, this.handleError);
    // binhdq fix phân quyền
    instance.interceptors.request.use(config => {
      if(config.method == 'post') {
        if (!config.data) {
          config.data = {};
        }
        if(typeof config.data == 'object') {
          // Thêm phân quyền vào mỗi request
          config.data.wareHouseName = localStorage.getItem(keys.warehouse_name) || "C";
        }
      }
      return config
    })

    this.instance = instance;
  }

  handleSucess(res) {
    // mapping mã lỗi -> thông báo lỗi cho user hiểu 
    return {...res, data: {...res.data , message: ERROR_MSG[res.data?.message] || res.data?.message || '' }}
  }

  handleError(e) {
    HLog(e);
    // if (e.response.data.code === 15) {
    //   localStorage.removeItem('Authorization')
    //   localStorage.removeItem('userInfo')
    //   window.location.reload();
    // }
    return Promise.reject(e);
  }

  async get(url) {
    try{
      
      return this.instance.get(url, {
        headers: {
          Authorization: await localGet(keys.access_token),
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });
    }catch(e){}
  }

  async post(url, body, responseType = '') {
    HLog("post url",url );
    HLog("post body",body );
    let header =  {
      Authorization: await localGet(keys.access_token),
      "Content-Type": "application/json",
      Accept: "application/json",
    }
    HLog(header);
    return this.instance.post(url, body, {
      headers: header,
      ...(!!responseType && {responseType})
    });
  }

  async put(url, body) {
    return this.instance.put(url, body, {
      headers: {
        Authorization: await localGet(keys.access_token),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  async delete(url) {
    return this.instance.delete(url, {
      headers: {
        Authorization: await localGet(keys.access_token),
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
  }

  
  async download(url) {
    try{
    return this.instance.get(url, {
      responseType: 'blob',
      headers: {
        Authorization: await localGet(keys.access_token),
      },
    });
    }catch(e){
      HLog(e.message);
    }
  }
  
}
export default new AxiosService();
