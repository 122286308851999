import { uniqBy } from 'lodash';

/**
 * Trả về list tôn cuộn không bị trùng nsmvCoilId. Hiện tại đang có bug xuất hiện 2 cuộn có cùng nsmvCoilId
 *
 * Update 26/08/2024
 * @returns
 */
export const uniqueRollList = rollArr => {
    return uniqBy(rollArr, 'nsmvCoilId');
};

/**
 * Làm tròn số đến 2 chữ số sau phần thập phân
 */
export const formatNumber = value => {
    const format = isNaN(value) || value == Infinity ? '0' : value.toFixed(2);
    return Number(format);
};
