import React, { createRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import style from './YeuCauSXBangXe.module.less';
import { Col, Button, DatePicker, Row, notification, Input } from 'antd';
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons';
import cn from 'classnames';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { produce } from 'immer';
import { debounce, sumBy, uniqueId } from 'lodash';
import BlockYeuCau from '../BlockYeuCau/BlockYeuCau';
import { isValidForm } from '../../../../../utils/validate';
import { localGet, localRemove, localSet } from '../../../../../helpers';
import { apis, keys, paths } from '../../../../../constants';
import { appLoading } from '../../../../../ducks/slices/appSlice';
import AxiosService from '../../../../../common/AxiosService';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';

const initialBangXe = {
    id_xe: 'xe_0',
    thicknessMin: '',
    thicknessProduct: '',
    sizeWidthTape: '',
    typeProduct: '',
    stripNumber: '',
    totalWidth: '',
    weightCal: '',
    note: '',
};

const initialTonCuon = {
    id: 'cuon_0',
    rollId: '',
    no: 1,
    sizeThickness: '',
    sizeWidth: '',
    type: '',
    nameCustomer: '',
    positionRoll1: '',
    positionRoll2: '',
    md: '',
    sizeWidthReal: '',
    weight: '',
    lstStandard: [initialBangXe],
    numberSplit: 1,
    sizeWidthVia: '',
    totalVia: '',
    ratioTrash: '',
    mass: '',
};

const initialBlock = {
    id: 'block_0',
    workOrderDate: '',
    lstBlock: [initialTonCuon],
};

const YeuCauSXBangXe = () => {
    const [state, setState] = useState([initialBlock]);
    const stateRef = useRef();
    const dispatch = useDispatch();
    const history = useHistory();

    //list rollID/ngày lệnh đã có trong danh sách
    //không cho user nhập trùng 2 trường này
    const lsRollId = state.reduce((prev, next) => prev.concat(next.lstBlock.map(i => i.rollId).filter(i => !!i)), []);
    const lsNgayLenh = state.reduce((prev, next) => prev.concat(next.workOrderDate), []);

    const [errState, setErrState] = useState([]);

    const [productNo, setProductNo] = useState('');
    const productNoRef = useRef();

    //store ref của từng cuộn
    const listCuon = state.reduce((prev, next) => {
        var ids = next.lstBlock.map(i => `${next.id}-${i.id}`);
        return prev.concat(ids);
    }, []);
    const [refCuon, setRefCuon] = useState([]);
    useEffect(() => {
        // console.log('List cuộn', listCuon.length);
        setRefCuon(refObj =>
            Array(listCuon.length)
                .fill()
                .map((_, i) => refObj[i] || createRef()),
        );
    }, [listCuon.length]);

    //store ref của từng block (nhiều cuộn)
    const [refBlock, setRefBlock] = useState([]);
    useEffect(() => {
        setRefBlock(refObj =>
            Array(state.length)
                .fill()
                .map((_, i) => refObj[i] || createRef()),
        );
    }, [state.length]);

    const onChangeBlock =
        (id, field, keyboardType = 'none') =>
        event => {
            let val = ['string', 'number'].includes(typeof event) ? event : event.target?.value;
            if (keyboardType == 'numeric') {
                val = val.replace(/[^0-9.]/g, '');
            }
            setState(
                produce(state => {
                    state.find(item => item.id == id)[field] = val;
                }),
            );
        };
    //prettier-ignore
    const onChangeCuon = 
        (tupleID, field, keyboardType = 'none') => event => {
            let val = ['string' ,'number'].includes(typeof event) ? event : event.target?.value
            if (keyboardType == 'numeric') {
                val = val.replace(/[^0-9.]/g, '');
            }
            const [idBlock, idCuon] = tupleID.split('-');
            setState(produce(state => {
                var lstBlock = state.find(item => item.id == idBlock)?.lstBlock;
                lstBlock.find(item => item.id == idCuon)[field] = val;
            }));
        }

    //prettier-ignore
    const onChangeXe = useCallback(
        (tupleID, field, keyboardType = 'none') => event => {
           
            let val = ['string' ,'number'].includes(typeof event) ? event : event.target?.value
            if (keyboardType == 'numeric') {
                val = val.replace(/[^0-9.]/g, '');
            }
            const [idBlock, idCuon, idXe] = tupleID.split('-');
            setState(produce(state => {
                var lstStandard = state.find(item => item.id == idBlock)?.lstBlock?.find(i => i.id == idCuon)?.lstStandard;
                lstStandard.find(item => item.id_xe == idXe)[field] = val
            }));
        },[]);

    //prettier-ignore
    const addCuon = useCallback((idBlock, initialData) => {
        setState(produce(state => {
            var lstBlock = state.find(item => item.id == idBlock)?.lstBlock;
            lstBlock.push({...initialTonCuon, ...initialData, id: uniqueId('cuon_'), isError: false, lstStandard: [initialBangXe] })
        }))
    }, []);

    //prettier-ignore
    const addBangXe = useCallback((tupleID, initialData) => {
        const [idBlock, idCuon] = tupleID.split('-');
        setState(produce(state => {
            var lstStandard = state.find(item => item.id == idBlock)?.lstBlock?.find(i => i.id == idCuon)?.lstStandard;
            lstStandard.push({...initialData, id_xe: uniqueId('xe_'), isError: false})
        }))
    },[]);

    const deleteCuon = useCallback(tupleID => {
        const [idBlock, idCuon] = tupleID.split('-');
        setState(
            produce(state => {
                var lstBlock = state.find(item => item.id == idBlock)?.lstBlock;
                if (lstBlock.length > 1) {
                    var delIndex = lstBlock.findIndex(i => i.id == idCuon);
                    lstBlock.splice(delIndex, 1);
                }
            }),
        );
    }, []);

    const addBlock = () => {
        setState(prev => prev.concat({ ...initialBlock, id: uniqueId('block_') }));
        // debounce(() => {
        //     console.log('scroll now');
        //     window.scrollTo(0, 0);
        // }, 200)();
    };
    const deleteBlock = idBlock => {
        setState(prev => (prev.length > 1 ? prev.filter(i => i.id != idBlock) : prev));
    };

    const deleteXe = useCallback(tupleID => {
        const [idBlock, idCuon, idXe] = tupleID.split('-');
        setState(
            produce(state => {
                var lstStandard = state.find(item => item.id == idBlock)?.lstBlock?.find(i => i.id == idCuon)?.lstStandard;
                if (lstStandard.length > 1) {
                    var delIndex = lstStandard.findIndex(i => i.id_xe == idXe);
                    lstStandard.splice(delIndex, 1);
                }
            }),
        );
    }, []);

    //validate form, show error
    const validateForm = () => {
        var newErrState = new Set(errState);
        let scrolled = false;

        //duyệt lần lượt từ block -> cuộn -> xẻ
        for (const [index, block] of state.entries()) {
            if (isValidForm(block)) {
                newErrState.delete(block.id);
            } else {
                if (!scrolled) {
                    scrolled = true;
                    refBlock[index].current?.scrollIntoView({ behavior: 'smooth' });
                }
                newErrState.add(block.id);
            }
            for (const [indexK, cuon] of block.lstBlock.entries()) {
                var id_cuon = `${block.id}-${cuon.id}`;
                var refIndex = sumBy(state.slice(0, index), i => i.lstBlock.length) + indexK;

                if (isValidForm(cuon, ['positionRoll1', 'positionRoll2', 'nameCustomer', 'md'])) {
                    //validate tỉ lệ phế mỗi cuộn phải >=0 mới cho submit yêu cầu
                    if (cuon.ratioTrash < 0) {
                        if (!scrolled) {
                            scrolled = true;
                            refCuon[refIndex].current?.scrollTo();
                            notification.error({ message: 'Khối lượng xẻ băng vượt quá trọng lượng ban đầu của cuộn' });
                        }
                        newErrState.add(id_cuon);
                    } else newErrState.delete(id_cuon);
                } else {
                    if (!scrolled) {
                        scrolled = true;
                        refCuon[refIndex].current?.scrollTo();
                    }
                    newErrState.add(id_cuon);
                }
                for (const xe of cuon.lstStandard) {
                    var id_xe = `${id_cuon}-${xe.id_xe}`;
                    if (isValidForm(xe, ['note', 'weightCal', 'totalWidth'])) {
                        newErrState.delete(id_xe);
                    } else {
                        if (!scrolled) {
                            scrolled = true;
                            refCuon[refIndex].current?.scrollTo();
                        }
                        newErrState.add(id_xe);
                    }
                }
            }
        }
        setErrState(Array.from(newErrState));
        //console.log(newErrState);
        if (newErrState.size > 0) {
            //console.log(state);
            return false;
        }
        return true;
    };

    const submitRequest = async () => {
        if (!validateForm()) return;
        if (!productNo) {
            notification.error({ message: 'Chưa nhập mã lệnh cho yêu cầu' });
            return;
        }
        const transformState = state.map(block => ({
            ...block,
            id: null,
            lstBlock: block.lstBlock.map(cuon => ({
                ...cuon,
                id: null,
                lstStandard: cuon.lstStandard.map(xe => ({
                    ...xe,
                    totalWidth: (Number(xe.sizeWidthTape) * Number(xe.stripNumber)).toFixed(2),
                    weightCal: ((Number(cuon.weight) * Number(xe.sizeWidthTape) * Number(xe.stripNumber)) / Number(cuon.sizeWidthReal)).toFixed(2),
                })),
            })),
        }));

        const dataSend = {
            id: null,
            productNo,
            workSplitDate: moment().format('DD/MM/YYYY'),
            lstBlockParent: transformState,
        };
        // console.log('Data send', dataSend);
        // return;
        dispatch(appLoading(true));
        try {
            const { data } = await AxiosService.post(apis.Url_Api_Post_YeuCauBangXe, dataSend);
            //console.log('Data response', data);
            if (data.status == 200) {
                notification.success({ message: 'Tạo yêu cầu thành công' });
                setState([initialBlock]);
                localRemove(keys.yeucau_bangxe);
                history.push(paths.main_ds_yeu_cau_xe_bang);
            } else {
                notification.error({ message: data.message });
            }
        } catch (e) {
            console.log('Error băng xẻ', e);
            notification.error({ message: 'Tạo yêu cầu thất bại' });
        } finally {
            dispatch(appLoading(false));
        }
    };

    //lưu trữ form vào local storage để user không phải edit lại khi chuyển trang
    // NOTE: nhớ xoá local storage này sau khi đã submit form !!!
    useEffect(() => {
        const savedForm = localGet(keys.yeucau_bangxe);
        const savedProductNo = localGet(keys.malenh_bangxe);
        if (!!savedForm) {
            setState(savedForm);
        }
        if (!!savedProductNo) {
            setProductNo(savedProductNo);
        }

        return () => {
            //console.log('Saved state', stateRef.current);
            localSet(keys.yeucau_bangxe, stateRef.current);
            localSet(keys.malenh_bangxe, productNoRef.current);
        };
    }, []);

    const submitAndPrint = async () => {
        if (!validateForm()) return;
        if (!productNo) {
            notification.error({ message: 'Chưa nhập mã lệnh cho yêu cầu' });
            return;
        }
        const transformState = state.map(block => ({
            ...block,
            id: null,
            lstBlock: block.lstBlock.map(cuon => ({
                ...cuon,
                id: null,
                lstStandard: cuon.lstStandard.map(xe => ({
                    ...xe,
                    totalWidth: (Number(xe.sizeWidthTape) * Number(xe.stripNumber)).toFixed(2),
                    weightCal: ((Number(cuon.weight) * Number(xe.sizeWidthTape) * Number(xe.stripNumber)) / Number(cuon.sizeWidthReal)).toFixed(2),
                })),
            })),
        }));
        //implement later
    };

    useEffect(() => {
        stateRef.current = state;
    }, [state]);
    useEffect(() => {
        productNoRef.current = productNo;
    }, [productNo]);

    const onChangeNgayLenh = (dateString, id) => {
        //về sau có thể cho phép user nhập trùng ngày lệnh, app sẽ tự động gộp 2 tôn cuộn của cùng 1 ngày
        if (lsNgayLenh.includes(dateString)) {
            notification.error({ message: 'Ngày lệnh đã tồn tại' });
        } else onChangeBlock(id, 'workOrderDate')(dateString);
    };

    return (
        <div className={style['main']}>
            <Row>
                <Col span={6}>
                    <h3 style={{ color: 'black' }}>Yêu cầu sản xuất băng xẻ</h3>
                </Col>
                <Col span={1}>
                    <h4 style={{ color: 'black' }}>Mã lệnh</h4>
                </Col>
                <Col span={4} className={style['block-title']}>
                    <Input className={cn(style['block-input'])} value={productNo} onChange={({ target }) => setProductNo(target.value)} />
                </Col>
                <Col span={13}>
                    <div style={{ display: 'flex', flexDirection: 'row-reverse', gap: '10px' }}>
                        <Button className={cn(style['filter-button'], style['button'])} style={{ height: '34px' }} onClick={submitAndPrint}>
                            {/* <SaveFilled style={{ color: '#2c3782' }} /> */}
                            Lưu & In
                        </Button>
                        <Button className={cn(style['ghost-button'], style['button'])} style={{ height: '34px' }} onClick={submitRequest}>
                            {/* <SaveFilled style={{ color: '#2c3782' }} /> */}
                            Lưu yêu cầu
                        </Button>
                    </div>
                </Col>
            </Row>
            {state.map((item, indexJ, arrState) => (
                <div key={item.id} ref={refBlock[indexJ]}>
                    <Row style={{ marginTop: '15px', paddingLeft: '5px', alignItems: 'center' }}>
                        <Col span={2} className={style['block-title']}>
                            Ngày lệnh
                        </Col>
                        <Col span={4} className={style['block-title']}>
                            <DatePicker
                                format={'DD/MM/YYYY'}
                                className={cn(style['block-input'], errState.includes(item.id) && item.workOrderDate == '' && style['warning-input'])}
                                placeholder=""
                                value={!!item.workOrderDate && item.workOrderDate !== '' ? dayjs(item.workOrderDate, 'DD/MM/YYYY') : undefined}
                                onChange={(date, dateString) => onChangeNgayLenh(dateString, item.id)}
                            />
                        </Col>

                        <Col span={8} offset={10}>
                            <Row style={{ gap: '10px', flexDirection: 'row-reverse' }}>
                                <Button className={cn(style['filter-button'], style['delete-button'])} style={{ height: '34px' }} onClick={() => deleteBlock(item.id)}>
                                    <DeleteOutlined style={{ color: 'white' }} />
                                    Xoá lệnh
                                </Button>
                                <Button className={cn(style['filter-button'], style['add-button'])} style={{ height: '34px' }} onClick={addBlock}>
                                    <PlusOutlined style={{ color: 'white' }} />
                                    Thêm lệnh
                                </Button>
                            </Row>
                        </Col>
                    </Row>
                    {item.lstBlock.map((i, indexK) => {
                        const refIndex = sumBy(arrState.slice(0, indexJ), item => item.lstBlock.length) + indexK;
                        return (
                            <BlockYeuCau
                                ref={refCuon[refIndex]}
                                key={`${item.id}-${i.id}}`}
                                errors={errState}
                                idBlock={item.id}
                                item={i}
                                addBangXe={addBangXe}
                                addCuon={addCuon}
                                onChangeCuon={onChangeCuon}
                                onChangeXe={onChangeXe}
                                deleteCuon={deleteCuon}
                                deleteXe={deleteXe}
                                lsRollId={lsRollId}
                                lsNgayLenh={lsNgayLenh}
                            />
                        );
                    })}
                </div>
            ))}
        </div>
    );
};

export default memo(YeuCauSXBangXe);
