import { Layout, Spin } from "antd";
import { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { paths } from "../constants";
import { Header, MainSider, SubSider } from "../layouts/main";
import { NotFound } from "../pages/common";
import { ScrollToTop } from "../hooks";
import { useThemeSwitcher } from "react-css-theme-switcher";
import { HomePage } from "../pages/main";
import DanhSachTonKho from "../pages/main/DanhSachTonKho/DanhSachTonKho";
import ThemMoiNhapKho from "../pages/main/NhapKho/ThemMoiNhapKho/ThemMoiNhapKho";
import DanhSachChiThiNhap from "../pages/main/NhapKho/DanhSachChiThiNhap/DanhSachChiThiNhap";
import XuatKho from "../pages/main/XuatKho/TaoChiThiXuatKho/XuatKho";
import DanhSachChiThiXuatKho from "../pages/main/XuatKho/DanhSachChiThiXuatKho/DanhSachChiThiXuatKho";
import DanhSachXuatKho from "../pages/main/XuatKho/DanhSachXuatKho/DanhSachXuatKho";
import TaoChiThiKiemKe from "../pages/main/KiemKe/TaoChiThiKiemKe/TaoChiThiKiemKe";
import DanhSachChiThiKiemKe from "../pages/main/KiemKe/DanhSachChiThiKiemKe/DanhSachChiThiKiemKe";
import XuatBaoCao from "../pages/main/BaoCao/XuatBaoCao/XuatBaoCao";
import ThongKe from "../pages/main/BaoCao/ThongKe/ThongKe";
import DanhSachYeuCauXeBang from "../pages/main/NhapKho/DanhSachYeuCauXeBang/DanhSachYeuCauXeBang";
import { LoadingModal } from "../components/common";
import { useSelector } from "react-redux";
import LoadingModalV2 from "../components/common/LoadingModal/LoadingModalV2";

const MainRoute = ({ location }) => {
  const [collapsed, setCollapsed] = useState(false);
  const { switcher, status, themes } = useThemeSwitcher();
  const { pathname } = location;
  const app = useSelector(state => state.app)
  const wareHouseName = useSelector(state => state.auth.wareHouseName)

  useEffect(() => {
    const isAuth = pathname.slice(0, 5) === paths.auth;
    switcher({ theme: isAuth ? themes.auth : themes.main });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  // Avoid theme change flicker
  if (status === "loading") {
    return <Spin spinning={true} />;
  }

  return (
    <>
      <ScrollToTop />

      <Layout>
        <MainSider collapsed={collapsed} />

        <Layout>
          <Header
            collapsedSider={collapsed}
            toggleSider={() => setCollapsed((val) => !val)}
          />

            <Layout.Content style={{height: 'calc(100vh - 64px)', overflowX: 'hidden', overflowY: 'scroll', position: 'relative'}}>
              <Switch>
                <Route
                  exact
                  path={paths.main_tong_quan}
                  component={HomePage}
                />

                <Route
                  exact
                  path={paths.main_danh_sach_ton_kho}
                  component={DanhSachTonKho}
                />

                <Route
                  exact
                  path={paths.main_them_moi_xuat_kho}
                  component={XuatKho}
                />

                <Route
                  exact
                  path={paths.main_danh_sach_chi_thi_xuat_kho}
                  component={DanhSachChiThiXuatKho}
                />

                <Route
                  exact
                  path={paths.main_danh_sach_xuat_kho}
                  component={DanhSachXuatKho}
                />

                <Route
                  exact
                  path={paths.main_them_moi_nhap_kho}
                  component={ThemMoiNhapKho}
                />

                <Route
                  exact
                  path={paths.main_danh_sach_nhap_kho}
                  component={DanhSachChiThiNhap}
                />

                <Route
                  exact
                  path={paths.main_them_moi_chi_thi_kiem_ke}
                  component={TaoChiThiKiemKe}
                />

                <Route
                  exact
                  path={paths.main_danh_sach_chi_thi_kiem_ke}
                  component={DanhSachChiThiKiemKe}
                />

                <Route
                  exact
                  path={paths.main_bao_cao_xuat_file}
                  component={XuatBaoCao}
                />

                <Route
                  exact
                  path={paths.main_bao_cao_thong_ke}
                  component={ThongKe}
                  
                />

                <Route
                  exact
                  path={paths.main_ds_yeu_cau_xe_bang}
                  // component={DanhSachYeuCauXeBang}
                  render={props=> wareHouseName == "X" ? <DanhSachYeuCauXeBang {...props}/> : <Redirect to={paths.main_danh_sach_nhap_kho}/>}
                />

                <Redirect exact from={paths.main} to={paths.main_tong_quan} />
                <Route component={NotFound} />
              </Switch>
           
            {/* <LoadingModal loading = {app.loading} /> */}
            <LoadingModalV2 loading = {app.loading} />
            </Layout.Content>
            {/* <SubSider /> */}
          </Layout>
      </Layout>
    </>
  );
};

export default withRouter(MainRoute);
